import { ImgHTMLAttributes, useState } from "react";
import { useSetPageParam } from "@hooks/useSetPageParam";
import { SearchBasicCardProps } from "@pages/Search/utils/types";
import { KeyValuePair } from "@typings/Cue";
import { defaultTimestampFormat } from "@util/constant";
import {
  cn,
  getDefaultImage,
  getFormattedTime,
  getFormattedUrlPath,
} from "@util/helpers";
import dayjs from "dayjs";

export default function SearchBasicCard({
  result,
  cardCount = 0,
}: SearchBasicCardProps): React.ReactElement {
  const [imgError, isImgError] = useState(false);
  const pubDate = dayjs.unix(result.pubdateunix).toString();

  const imgHTMLProps: ImgHTMLAttributes<HTMLImageElement> = {
    loading: "lazy",
    alt: result.title,
    width: 120,
    height: 80,
    className: "w-full",
  };

  const filterParamater: KeyValuePair[] = [
    { key: "w", value: "240" },
    { key: "h", value: "160" },
    { key: "dpr", value: "1" },
    { key: "f", value: "webp" },
  ];

  const { ref } = useSetPageParam({ cardIndex: cardCount });

  return (
    <div
      ref={ref}
      data-testid="search-basic-card-component"
      className={cn("mb-6 flex", {
        "border-t border-gray-175 pt-6": cardCount !== 0,
      })}
    >
      <div className="mr-2 xs:mr-8 md:flex">
        <div className="mr-8 min-w-[140px] flex-none">
          <p className="font-poppins text-4xs text-gray-550">
            {getFormattedTime(pubDate, defaultTimestampFormat)}
          </p>
        </div>

        <a
          href={result.link}
          target="_blank"
          className="group"
          rel="noreferrer"
        >
          <p className="mb-1 font-lct font-bold group-hover:underline">
            {result.title}
          </p>

          <p className="line-clamp-3 font-poppins text-2xs text-gray-750">
            {result.description}
          </p>
        </a>
      </div>

      <a
        href={result.link}
        target="_blank"
        className="ml-auto h-full w-full max-w-[100px] flex-none md:max-w-[120px]"
        rel="noreferrer"
      >
        <img
          src={
            imgError
              ? getDefaultImage("")
              : getFormattedUrlPath(result.image, filterParamater)
          }
          {...imgHTMLProps}
          onError={() => {
            isImgError(true);
          }}
        />
      </a>
    </div>
  );
}
